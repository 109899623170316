<template>
  <div>
    <div v-if="global.userLevel == 1" class="file-upload-area">
      <va-file-upload v-model="filesToUpload" dropzone />
      <va-button :loading="isUploading" v-if="filesToUpload.length > 0" class="upload-button" @click="uploadFiles()">Upload</va-button>
    </div>
    <div class="file-list">
      <h1>Available Files</h1>
      <div v-if="isGetttingFileList">Getting files, please wait...</div>
      <div class="file-item" v-for="file in fileList" :key="file">
        <va-button :disabled="deletingFile==file" class="download-button" size="small" :loading="downloadingFile==file" @click="downloadFile(file)"><va-icon size="small" class="download-icon" name="download" /></va-button>
        <va-button v-if="global.userLevel == 1" :disabled="downloadingFile==file" color="danger" class="delete-button" size="small" :loading="deletingFile==file" @click="deleteFile(file)"><va-icon size="small" class="delete-icon" name="delete" /></va-button>
        {{ file }}
      </div>
    </div>

    <va-modal blur no-dismiss v-model="showDeleteConfirm" title="Delete Document" @ok="doDeleteFile()">
      <p>Document {{ fileToDelete }} will be removed.</p>
      <p>Are you sure you wish to continue?</p>
    </va-modal>

  </div>
</template>

<script>
import { globalStore } from "@/stores/globalstore";
export default {
  name: "Main-View",
  data() {
    return {
      global: globalStore(),
      filesToUpload: [],
      fileList: [],
      isUploading: false,
      isGettingFileList: false,
      downloadingFile: "",
      fileToDelete: "",
      deletingFile: "",
      showDeleteConfirm: false
    };
  },
  methods: {
    async uploadFiles() {
      var formData = new FormData();
      this.filesToUpload.forEach((f) => {
        formData.append("file", f);
      });

      this.isUploading = true;
      await this.global.webApiPost("UploadDocument", formData, false, "multipart/form-data");
      if (!this.global.lastApiError) {
        this.filesToUpload = [];
        this.getFileList();
      }
      this.isUploading = false;
    },
    async downloadFile(fileName) {
      this.downloadingFile=fileName;
      await this.global.webApiDownload(`DownloadDocument/${fileName}`, fileName);
      this.downloadingFile="";
    },
    deleteFile(fileName) {
      this.fileToDelete=fileName;
      this.showDeleteConfirm=true;
    },
    async doDeleteFile() {
      this.deletingFile=this.fileToDelete;
      await this.global.webApiDelete(`DeleteDocument/${this.fileToDelete}`, this.fileToDelete);
      this.deletingFile="";
      this.fileToDelete="";
      this.getFileList();
    },  
    async getFileList() {
      this.isGettingFileList = true;
      var response = await this.global.webApiGet("Documents");
      if (!this.global.lastApiError) {
        this.fileList = response.data;
      }
      this.isGettingFileList = false;
    },
  },
  async mounted() {
    if (!this.global.token) {
      this.$router.push("/login");
    }
    this.getFileList();
  },
  components: {},
};
</script>

<style>
.file-upload-area {
  margin-bottom: 25px;
}

.file-list {
  margin-left: 25px;
  text-align: left;
}

.file-list h1 {
  margin-bottom: 25px;
}

.file-item {
  margin-top: 20px;
  margin-bottom: 20px;
}

.download-button {
  margin-right:10px;
}

.delete-button {
  margin-right:10px;
}


</style>
