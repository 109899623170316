import { createApp } from "vue"
import { createRouter, createWebHistory } from "vue-router"
import { createVuestic } from "vuestic-ui"
import App from "./App.vue"
import MainView from "./components/Main-View"
import LoginView from "./components/Login-View"
import { createPinia } from "pinia"
import piniaPersist from "pinia-plugin-persist"

import "vuestic-ui/css"

const routes = [
    { path: '/', component: MainView },
    { path: '/login', component: LoginView }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

const pinia = createPinia()
pinia.use(piniaPersist)

createApp(App)
    .use(router)
    .use(createVuestic())
    .use(pinia)
    .mount('#app')