<template>
  <div class="login-form">
    <va-input v-model="userName" class="mb-12 user-name" placeholder="Enter user name" label="User Name" />
    <va-input v-model="password" class="mb-12 password" type="password" placeholder="Enter password" label="Password" />

    <va-button :loading="isBusy" class="login-button" @click="login()">Log In</va-button>
    <div class="error-text" v-if="loginError">Incorrect username or password</div>
  </div>
</template>

<script>
import { globalStore } from "@/stores/globalstore";
export default {
  name: "Login-View",
  data() {
    return {
      global: globalStore(),
      userName: "",
      password: "",
      loginError: false,
      isBusy: false,
    };
  },
  methods: {
    async login() {
      var data = {
        username: this.userName,
        password: this.password,
      };
      this.isBusy = true;
      var response = await this.global.webApiPost("Login", data);
      this.isBusy = false;
      if (!this.global.lastApiError) {
        this.global.token = response.data.token;
        this.global.userLevel = response.data.userLevel;
        this.$router.push("/");
      } else {
        this.loginError = this.global.lastApiError.response.status == 401;
      }
    },
  },
  async mounted() {},
  components: {},
};
</script>

<style>
.login-form {
  width: 300px;
  margin: 0 auto;
}

.login-form .user-name {
  margin-top: 10px;
  margin-bottom: 10px;
}

.login-form .login-button {
  margin-top: 10px;
}

.login-form .error-text {
  color: #f00;
}
</style>
